<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="true"
    ></loading>
    <div>
      <div animated fadeIn>
        <b-row>
          <!-- Personal info -->
          <b-col lg="10" offset-lg="1">
            <CCard accent-color="warning">
              <CCardHeader>
                <strong>Editar perfil</strong>
                <div class="card-header-actions">
                  <CButton
                    class="s-bg s-bg-text"
                    @click="submit"
                    style="margin-left:0.3rem;"
                    :disabled="!terms_and_conditions"
                  >
                    <strong>Guardar</strong>
                  </CButton>
                </div>
              </CCardHeader>
              <CCardBody>
                <b-row v-if="error">
                  <b-col cols="12">
                    <div
                      class="alert alert-danger"
                      role="alert"
                      v-for="error_message in errors"
                      v-bind:key="error_message"
                    >
                      {{ error_message }}
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- FirstName -->
                  <b-col sm="6">
                    <b-form-group>
                      <label for="firstName">
                        <strong>
                          Nombres
                          <small class="alert-text">*</small>
                        </strong>
                      </label>
                      <b-form-input
                        type="text"
                        id="firstName"
                        v-model.trim="$v.firstName.$model"
                        placeholder="Inserta tus nombres"
                        :class="{
                          'form-control is-invalid': $v.firstName.$error,
                          'form-control is-valid': !$v.firstName.$invalid
                        }"
                      ></b-form-input>
                      <b-form-invalid-feedback v-if="!$v.firstName.required"
                        >Tu nombre es necesario</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback v-if="!$v.firstName.minLength"
                        >El primer nombre debe tener como mínimo
                        {{
                          $v.firstName.$params.minLength.min
                        }}
                        letras</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                  <!-- Lastname -->
                  <b-col sm="6">
                    <b-form-group>
                      <label for="lastName">
                        <strong>
                          Apellido
                          <small class="alert-text">*</small>
                        </strong>
                      </label>
                      <b-form-input
                        type="text"
                        id="lastName"
                        v-model.trim="$v.lastName.$model"
                        placeholder="Inserta tu apellido"
                        :class="{
                          'form-control is-invalid': $v.lastName.$error,
                          'form-control is-valid': !$v.lastName.$invalid
                        }"
                      ></b-form-input>
                      <b-form-invalid-feedback v-if="!$v.lastName.required"
                        >Tu apellido es necesario</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback v-if="!$v.lastName.minLength"
                        >Tu apellido debe tener como mínimo
                        {{
                          $v.lastName.$params.minLength.min
                        }}
                        letras</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>

                  <!-- Address -->
                  <b-col sm="6">
                    <b-form-group>
                      <label for="address">
                        <strong>
                          Tu Dirección
                          <small class="alert-text">*</small>
                        </strong>
                        <small class="form-text text-muted w-100"
                          >Ingresa la dirección donde vives.</small
                        >
                      </label>

                      <b-form-input
                        type="text"
                        id="address"
                        v-model.trim="$v.address.$model"
                        :class="{
                          'form-control is-invalid': $v.address.$error,
                          'form-control is-valid': !$v.address.$invalid
                        }"
                      ></b-form-input>

                      <b-form-invalid-feedback v-if="!$v.address.required"
                        >Tu dirección es necesaria</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>

                  <!-- City-->
                  <b-col sm="6">
                    <b-form-group>
                      <label for="city">
                        <strong>
                          Tu ciudad
                          <small class="alert-text">*</small>
                        </strong>
                        <small class="form-text text-muted w-100"
                          >Busca tu ciudad y selecciona la que coincida</small
                        >
                      </label>

                      <vue-google-autocomplete
                        v-model.trim="$v.city.$model"
                        id="city"
                        ref="city"
                        class="form-control"
                        :class="{
                          'form-control is-invalid': $v.city.$error,
                          'form-control is-valid': !$v.city.$invalid
                        }"
                        placeholder="Selecciona tu ciudad con Google Maps"
                        v-on:placechanged="getAddressData"
                        v-on:no-results-found="cityDataNotFound"
                        v-on:inputChange="cityDataChanged"
                        v-on:blur="cityBlur"
                        :country="['co', 'pe']"
                        autocomplete="off"
                        types="(cities)"
                      ></vue-google-autocomplete>

                      <b-form-invalid-feedback
                        v-if="!$v.city.required || !$v.city.minLength"
                        >Tu ciudad de residencia es necesaria, busca y
                        selecciona las opciones de
                        Google</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>

                  <!-- Phone -->
                  <b-col md="6">
                    <b-form-group>
                      <label for="Phone">
                        <strong>
                          Tu número de teléfono
                          <small class="alert-text">*</small>
                        </strong>
                      </label>
                      <vue-tel-input
                        v-model.trim="$v.phone_number.$model"
                        placeholder="Tu número de teléfono"
                        :class="{
                          'form-control is-invalid': $v.phone_number.$error,
                          'form-control is-valid': !$v.phone_number.$invalid
                        }"
                        v-bind="phoneNumberProps"
                      ></vue-tel-input>
                      <b-form-invalid-feedback v-if="!$v.phone_number.minLength"
                        >El número de teléfono debe tener como mínimo
                        {{
                          $v.phone_number.$params.minLength.min
                        }}
                        números.</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback
                        v-if="!$v.phone_number.phoneNumber"
                        >Debe tener solo números.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>

                  <!-- Email -->
                  <b-col md="6">
                    <label for="contact_email">
                      <strong>Tu email de contacto</strong>
                      <small class="alert-text">*</small>
                    </label>
                    <b-form-group>
                      <b-form-input
                        type="text"
                        id="email"
                        v-model.trim="$v.email.$model"
                        :disabled="disabledEmail"
                        :class="{
                          'form-control is-invalid': $v.email.$error,
                          'form-control is-valid': !$v.email.$invalid
                        }"
                      ></b-form-input>
                      <b-form-invalid-feedback v-if="!$v.email.required"
                        >Tu email de contacto es
                        necesario</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback v-if="!$v.email.email"
                        >Tu email de contacto no parece
                        valido</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>

                  <!-- Document -->
                  <b-col lg="6">
                    <b-form-group>
                      <b-row>
                        <b-col lg="4">
                          <small>Tipo documento</small>
                          <b-form-select
                            id="typeDoc"
                            v-model="typeDocSelected"
                            :options="typeDocs"
                            :class="{
                              'form-control is-invalid':
                                $v.typeDocSelected.$error,
                              'form-control is-valid': !$v.typeDocSelected
                                .$invalid
                            }"
                          ></b-form-select>
                          <b-form-invalid-feedback
                            v-if="!$v.typeDocSelected.required"
                            >Debes seleccionar un tipo de
                            documento</b-form-invalid-feedback
                          >
                        </b-col>
                        <b-col lg="8">
                          <small>Número de documento</small>
                          <b-form-input
                            type="number"
                            id="numDoc"
                            v-model.trim="$v.numDocument.$model"
                            placeholder="Número de documento"
                            :class="{
                              'form-control is-invalid': $v.numDocument.$error,
                              'form-control is-valid': !$v.numDocument.$invalid
                            }"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            v-if="!$v.numDocument.required"
                            >Tú número de documento es
                            obligatorio</b-form-invalid-feedback
                          >
                          <b-form-invalid-feedback
                            v-if="!$v.numDocument.minLength"
                            >El documento debe tener como mínimo
                            {{
                              $v.numDocument.$params.minLength.min
                            }}
                            dígitos</b-form-invalid-feedback
                          >
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>

                  <!-- Occupation -->
                  <b-col sm="6">
                    <b-form-group>
                      <label for="occupation">
                        <strong>
                          Ocupación
                          <small class="alert-text">*</small>
                        </strong>
                      </label>
                      <b-form-input
                        type="text"
                        id="occupation"
                        v-model.trim="$v.occupation.$model"
                        placeholder="Inserta tu ocupación"
                        :class="{
                          'form-control is-invalid': $v.occupation.$error,
                          'form-control is-valid': !$v.occupation.$invalid
                        }"
                      ></b-form-input>
                      <b-form-invalid-feedback v-if="!$v.occupation.required"
                        >Tu apellido es necesario</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>

                  <!-- Map -->
                  <b-col lg="12">
                    <section>
                      <label for="map">
                        <strong>Tu ubicación:</strong>
                      </label>
                      <br />Has clic en el icono rojo y arrastralo hasta tu
                      lugar de residencia.
                      <GmapMap
                        :center="center"
                        :zoom="16"
                        style="height: 30rem"
                      >
                        <GmapMarker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          :label="m.label"
                          :title="m.title"
                          :clickable="true"
                          :draggable="m.draggable"
                          @click="toggleInfoWindow(m, index)"
                          @drag="updateCoordinates"
                        />
                      </GmapMap>
                      <div v-for="(marker, index) in markers">
                        <strong>Latitud:</strong>
                        {{ marker.position.lat }}
                        <br />
                        <strong>Longitud:</strong>
                        {{ marker.position.lng }}
                        <br />
                      </div>
                    </section>
                  </b-col>
                </b-row>
              </CCardBody>
              <CCardFooter>
                <b-row>
                  <b-col lg="12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        v-model="terms_and_conditions"
                      />
                      <label class="form-check-label" for="gridCheck">
                        Al continuar aceptas los
                        <a v-on:click="goTo('termsProvider')">
                          <span class="text-link-color"
                            >Términos y Condiciones para proveedores</span
                          >
                        </a>
                        de Go Guau
                      </label>
                    </div>
                  </b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col
                    lg="3"
                    offset-lg="3"
                    md="4"
                    offset-md="2"
                    sm="6"
                    style="text-align:center; margin-bottom:1rem;"
                  >
                    <b-button
                      class="mr-1 s-bg s-bg-text"
                      style="width:100%;"
                      @click="submit"
                      :disabled="!terms_and_conditions"
                    >
                      <strong>Guardar</strong>
                    </b-button>
                  </b-col>
                  <b-col
                    lg="3"
                    md="4"
                    sm="6"
                    style="text-align:center; margin-bottom:1rem;"
                  >
                    <b-button class="mr-1" style="width:100%;" @click="cancel"
                      >Cancelar</b-button
                    >
                  </b-col>
                </b-row>
              </CCardFooter>
            </CCard>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
  requiredUnless
} from "vuelidate/lib/validators";
import firebase from "firebase";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { setupCalendar, DatePicker } from "v-calendar";
import moment from "moment";
import Loading from "vue-loading-overlay";
import vSelect from "vue-select";
import decoder from "google-geo-decoder";
// import {userProfileMixin} from '../../mixins/userProfile';

const today = new Date();

const phoneNumber = number => {
  if (!!number) {
    return !!number.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g);
  } else {
    return false;
  }
};

setupCalendar({
  firstDayOfWeek: 2 // Monday
});

export default {
  name: "Create",
  components: {
    Loading,
    VueGoogleAutocomplete,
    "v-date-picker": DatePicker,
    vSelect
  },
  // mixins: [userProfileMixin],
  data() {
    return {
      loading: true,
      error: null,
      errors: [],
      firstName: null,
      lastName: null,
      city: null,
      address: null,
      phone_number: null,
      phoneNumberProps: {
        mode: "international",
        defaultCountry: "CO",
        enabledCountryCode: true
      },
      email: null,
      occupation: null,
      userType: null,
      typeDocs: [
        { value: null, text: "SELECCIONE" },
        { value: 1, text: "CC - Cédula de ciudadanía" },
        { value: 2, text: "CE - Cédula de extranjería" },
        {
          value: 3,
          text: "CEL - En caso de identificarse a través de la línea del móvil"
        },
        { value: 4, text: "DE - Documento de identificación extranjero" },
        {
          value: 5,
          text:
            "IDC - Identificador único de cliente, para el caso de ID’s únicos de clientes/usuarios de servicios públicos"
        },
        { value: 6, text: "NIT - Número de Identificación Tributario" },
        { value: 7, text: "PP - Pasaporte" },
        { value: 8, text: "RC - Registro civil de nacimiento" },
        { value: 9, text: "TI - Tarjeta de Identidad" }
      ],
      typeDocSelected: null,
      numDocument: null,
      // Map attributes
      center: { lat: 2.442404, lng: -76.605833 },
      markers: [
        //user
        {
          position: { lat: 2.442404, lng: -76.605833 },
          label: "",
          draggable: true,
          title: "Casa"
        }
      ],
      disabledEmail: false,
      terms_and_conditions: true,
      cities: null,
      cityMatched: null
    };
  },
  created() {
    this.cities = this.$store.getters.cities;
    console.log(this.cities);
    let userId = this.$store.getters.userId;
    // console.log('userCreate-userId:', userId)
    let userProfile = JSON.parse(this.$store.getters.userProfile);
    if (userProfile != null) {
      console.log("create-profile:", userProfile);
      this.fetchProfile(userProfile);
      // this.userProfile = userProfile
      // this.userCurrency = this.userProfile.currency != null ? this.userProfile.currency : 'COP';
      // this.hasUserProfile = true
    } else {
      if (this.$firebase.auth().currentUser.email != null) {
        this.email = this.$firebase.auth().currentUser.email;
      }
      if (this.$firebase.auth().currentUser.displayName != null) {
        this.firstName = this.$firebase.auth().currentUser.displayName;
      }
    }
    this.loading = false;
    this.$getLocation({}).then(coordinates => {
      this.markers[0].position = coordinates;
      this.center = coordinates;

      const [lon, lat] = [coordinates.lat, coordinates.lng];
      const key = "AIzaSyDH2Q73_1TQ9RqCEyqIJXDmVsey9btIhTI";
      decoder(lon, lat, key).then(res => {
        const city = res.lev2;
        const department = res.lev1;
        const country = res.country;
        const fullCity = city + ", " + department + ", " + country;
        this.city = fullCity;
        this.cityFilter();
      });
    });
  },
  computed: {
    emailInLowerCase() {
      return this.email.toLowerCase().trim();
    },
    fullName: {
      get() {
        this.firstName = this.firstName.trim().replace("  ", " ");
        this.lastName = this.lastName.trim().replace("  ", " ");
        let fullname = this.firstName + " " + this.lastName;
        fullname
          .trim()
          .replace("  ", " ")
          .split(" ", 5);
        return fullname;
      },
      set(newValue) {
        return newValue;
      }
    }
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(2)
    },
    lastName: {
      required,
      minLength: minLength(2)
    },
    city: {
      required,
      minLength: minLength(4)
    },
    address: {
      required
    },
    email: {
      required,
      email
    },
    phone_number: {
      required,
      minLength: minLength(7),
      phoneNumber
    },
    typeDocSelected: {
      required
      // required: requiredIf(function(){
      // if(this.flagPSE){
      //     return true
      // }else{
      //     return false
      // }
      // })
    },
    numDocument: {
      required,
      // required: requiredIf(function(){
      // if(this.flagPSE){
      //     return true
      // }else{
      //     return false
      // }
      // }),
      minLength: minLength(8)
    },
    occupation: {
      required
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = true;
        this.errors = ["No has completado correctamente tus datos"];
        this.scrollToTop();
      } else {
        this.sendRequest();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    sendRequest() {
      let userId = this.$store.getters.userId;
      let data = {
        firstName: this.firstName,
        lastName: this.lastName,
        fullName: this.fullName,
        phone_number: this.phone_number,
        address: this.address,
        city: this.city,
        cityMatched: this.cityMatched,
        email: this.emailInLowerCase,
        position: {
          lat: this.markers[0].position.lat,
          lng: this.markers[0].position.lng,
          accuracy: this.markers[0].position.accuracy
        },
        userId,
        typeDoc: this.typeDocSelected,
        numDocument: this.numDocument,
        occupation: this.occupation
      };
      // console.log(data);
      this.postProviderProfile(data);
    },
    postProviderProfile(userProfile) {
      let createProviderProfile = this.$firebase
        .functions()
        .httpsCallable("createProvider");
      this.loading = true;
      createProviderProfile(userProfile)
        .then(result => {
          this.error = false;
          if (result.data != null && !result.error) {
            // console.log(result.data.data);
            this.setUser(result.data.data);
          }
        })
        .catch(error => {
          this.loading = false;
          console.log("error create user:", error);
        });
    },
    setUser(userData) {
      let userProfile = JSON.parse(this.$store.getters.userProfile);
      if (userProfile == null) {
        this.$store
          .dispatch("fetchUser", { userId: userData.login_key })
          .then(response => {
            let userData = response;
            if (userData) {
              this.$store.commit("defUserDbId", userData.id);
              this.$store.commit("defUserProfile", JSON.stringify(userData));
              this.$router.replace("/users/profile/");
            }
            this.loading = false;
          })
          .catch(e => {
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.$router.replace("/users/profile/");
      }
    },
    getAddressData(addressData, placeResultData, id) {
      console.log(addressData);
      // console.log(placeResultData)
      let country = addressData.country;
      // let locality = addressData.locality
      let fomatted_address = placeResultData.formatted_address;
      this.currencySelected =
        country == "Perú" || country == "Peru" ? "PEN" : "COP";
      // this.city = locality != null ? locality :  fomatted_address
      this.city = fomatted_address;
      this.$v.$touch();
      let cityFound = this.cities.find(city => city.nombre == this.city);
      if (cityFound == undefined) {
        this.cityMatched = {
          value: 0,
          label: this.city
        };
      } else {
        this.cityMatched = {
          value: cityFound.id,
          label: cityFound.nombre
        };
      }
    },
    cityDataNotFound(address) {
      this.$v.city = null;
      // this.$v.$touch();
    },
    cityDataChanged(inputVal) {
      if (inputVal.newVal != this.city) {
        this.city = null;
      }
      this.$v.city.$touch();
      // this.$v.$touch();
    },
    cityBlur() {
      this.$v.city.$touch();
    },
    updateCoordinates(location) {
      this.markers[0].position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      };
    },
    fetchProfile(userProfile) {
      this.firstName = userProfile.nombre;
      this.lastName = userProfile.apellido;
      this.phone_number = userProfile.celular;
      this.city = userProfile.ciudad;
      this.address = userProfile.direccion;
      this.email = userProfile.email;
      this.city = userProfile.ciudad;
      this.markers[0].position = userProfile.ubicacion;
      this.center = userProfile.ubicacion;
      this.disabledEmail = true;
      this.userType = userProfile.type;
      this.typeDocSelected = userProfile.tipo_documento.id;
      this.numDocument = userProfile.documento;
      this.occupation = userProfile.ocupacion;
    },
    cancel() {
      this.$router.replace("/users/profile");
    },
    goTo(to) {
      if (to == "termsProvider") {
        let route = this.$router.resolve({ path: "/pages/termsprovider" });
        window.open(route.href, "_blank");
      }
    },
    cityFilter() {
      let cityFound = this.cities.find(city => city.nombre == this.city);

      if (cityFound == undefined) {
        this.cityMatched = {
          value: 0,
          label: this.city
        };
      } else {
        this.cityMatched = {
          value: cityFound.id,
          label: cityFound.nombre
        };
      }
    },
  }
};
</script>

<style scoped src="vue-tel-input/dist/vue-tel-input.css"></style>
